import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { addImprovement } from '@/@core/queries/improvements'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import useCommon from "@/views/organization/useCommon"
import useCommonTodo from '@/views/apps/todo/useCommonTodo'

export default function useCaseAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { formatDate } = useCommon()
  const { uploadImprovementFile, sendEmailImprovements, uploadAction_planFile } = useCommonTodo()
  const userData = store.state?.userStore?.userData
  const caseData = ref({ stage: 'registration', dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) })
  const isSubmitting = ref(false)

  const create = (caseData) => {
    isSubmitting.value = true

    const payload = {
      client_id: { link: userData.client.$oid },
      note: caseData.note,
      assignee: { link: caseData.assignee },
      dueDate: formatDate(caseData.dueDate),
      description: caseData.description || '',
      associatedCases: caseData.associatedCases ? { link: caseData.associatedCases } : null,
      areas: caseData.areas ? { link: caseData.areas } : null,
      proposalResponsible: { link: caseData.assignee },
      approvalResponsible: caseData.approvalResponsible ? { link: caseData.approvalResponsible } : null,
      verificationResponsible: caseData.verificationResponsible ? { link: caseData.verificationResponsible } : null,
      proposalDueDate: caseData.proposalDueDate ? formatDate(caseData.proposalDueDate) : null,
      approvalDueDate: caseData.approvalDueDate ? formatDate(caseData.approvalDueDate) : null,
      verificationDueDate: caseData.verificationDueDate ? formatDate(caseData.verificationDueDate) : null,
      stage: 'analysis',
      lastModifiedBy: userData?.worker_id?.$oid ? { link: userData.worker_id.$oid } : null,
      createdAt: new Date(),
    }

    axios
      .post('/graphql', {
        query: addImprovement,
        variables: { data: payload },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        showSuccessMessage(i18n.t('message.improvement_created'))

        // Upload document to AWS and then update the case in MongoDB with the AWS document key
        if (caseData.documentFile?.name) {
          const improvementId = data.data.insertOneImprovement._id
          uploadImprovementFile(caseData.documentFile, "documents", improvementId)
        }

        // Send email to assignee with the details of the case created
        sendEmailImprovements([caseData], 'add')
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-case-list' })
      })
  }

  return {
    create,
    caseData,
    isSubmitting,
  }
}
